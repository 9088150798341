import request from '@/router/axios';
import { Form } from 'element-ui';
import {userUrl} from '@/config/env';
export const list = (current,size,nameOrCode) => {
    return request({
        url : userUrl + "corporation/selectPage",
        method: "post",
        data: {
            pageNo : current,
            pageSize : size,
            nameOrCode
        }
    })
}
//计量单位下拉框初始化
export const unitOptionsInit = () => {
    return request({
        url : userUrl + "unit/selectShowUnit",
        method: "get"
    })
}

//纺纱颜色下拉框初始化
export const colourOptionsInit = () => {
    return request({
        url : userUrl + "tubeColor/selectAll",
        method: "get"
    })
}
//默认工序下拉框初始化
export const processOptionsInit = () => {
    return request({
        url : userUrl + "process/selectAll",
        method: "get"
    })
}
//规则下拉框初始化
export const ruleOptionsInit = () => {
    return request({
        url : userUrl + "codeRule/selectAllByCorpId",
        method: "get"
    })
}
//保存
export const saveData = (settings) => {
    return request({
        url : userUrl + "setting/saveSetting",
        method: "post",
        data: {
            settings
        }
    })
}
