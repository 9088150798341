var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "200px" } },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "系统默认基础配置", name: "1" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "默认时区:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[4].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[4], "value", $$v)
                                    },
                                    expression: "settings[4].value"
                                  }
                                },
                                _vm._l(_vm.timeZoneOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: item }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统功能配置", name: "2" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否为单锭系统:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.settings[2].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.settings[2], "value", $$v)
                                  },
                                  expression: "settings[2].value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统自动程序默认设置", name: "3" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标准计量单位:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[0].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[0], "value", $$v)
                                    },
                                    expression: "settings[0].value"
                                  }
                                },
                                _vm._l(_vm.unitOptions, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纺纱颜色:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[1].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[1], "value", $$v)
                                    },
                                    expression: "settings[1].value"
                                  }
                                },
                                _vm._l(_vm.colourOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "默认工序:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[3].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[3], "value", $$v)
                                    },
                                    expression: "settings[3].value"
                                  }
                                },
                                _vm._l(_vm.processOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成故障单号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[5].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[5], "value", $$v)
                                    },
                                    expression: "settings[5].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成批号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[6].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[6], "value", $$v)
                                    },
                                    expression: "settings[6].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成工艺单号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.settings[7].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[7], "value", $$v)
                                    },
                                    expression: "settings[7].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("Yes")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }